import { ApolloError } from '@apollo/client'
import approve from 'approvejs'
import moment from 'moment'

import { errorTitle } from './helper_ts'

import type { TObject } from '../../typeds/base.typed'
import type { TMessage, TMessageQuestion } from '../../components/message/message.typed'

export const abbreviateNumber = (value?: number) => {
  let result: string = ''
  if (value) {
    result = value.toString()
    if (value >= 1000) {
      const suffixes = ["", "k", "m", "b","t"]
      const suffixNum = Math.floor((""+value).length/3)
      let shortValue
      for (let precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000,suffixNum)) : value).toPrecision(precision))
        const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'')
        if (dotLessShortValue.length <= 2) {
          break 
        }
      }
      result = shortValue+suffixes[suffixNum]
    }
  }
  return result
}

export const currencyNumber = (value?: number | null) => {
  return (value !== undefined  && value !== null) ? value.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }) : '-'
}

export const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  }
}

export const generateMessage = (type: string, value: string | Element, callback?: () => void, timer?: number) => {
  let result: TMessage = {
    type: type,
    message: value,
    timer: timer || (type === 'warning' ? 3000 : 2000)
  } 
  callback && (result.callback = () => { callback() })
  return result
}

export const generateMessageQuestion = (type: string, title: string, subtitle: string, callback?: () => void, buttonSubmit?: string, cancelCallback?: () => void) => {
  let result: TMessageQuestion = {
    type: type,
    title: title,
    subtitle: subtitle,
    callback: callback,
    buttonSubmit: buttonSubmit,
    cancelCallback: cancelCallback
  } 
  return result
}

export const isEqual =  (value: any, other: any) => {
	const type = Object.prototype.toString.call(value)
	if (type !== Object.prototype.toString.call(other) || (['[object Array]', '[object Object]'].indexOf(type) < 0)) {
    return false
  }
	const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length
	const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length
	if (valueLen !== otherLen) {
    return false
  }
	const compare = function (item1: any, item2: any) {
		const itemType = Object.prototype.toString.call(item1)
		if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
			if (!isEqual(item1, item2)) {
        return false
      }
		} else {
			if (itemType !== Object.prototype.toString.call(item2)) {
        return false
      }
			if (itemType === '[object Function]') {
				if (item1.toString() !== item2.toString()) {
          return false
        }
			} else {
				if (item1 !== item2) {
          return false
        }
			}
		}
	}
	if (type === '[object Array]') {
		for (let i = 0; i < valueLen; i++) {
			if (compare(value[i], other[i]) === false) {
        return false
      }
		}
	} else {
		for (const key in value) {
			if (key in value) {
				if (compare(value[key], other[key]) === false) {
          return false
        }
			}
		}
	}
	return true
}

export const generateOptionValue = (value: any, label?: string) => {
  return value ? { value : value, label: (label ? label : value) } : null
}

export const generateOptionValueArray = (value: TObject[], isFixedArray?: TObject[]) => {
  let result: TObject[] = []
  if (Array.isArray(value)) {
    value.forEach(val => result.push({ value : val, label: val, isFixed : isFixedArray?.length ? isFixedArray.includes(val) : false }))
  }
  return result
}

export const isProduction = () => window.location.hostname === "app.forstok.com"

export const humanise = (value?: string) => {
  const underscoreMatch = /_/g, underscoreMatch2 = /-/g
  return value ? value.replace(underscoreMatch, " ").replace(underscoreMatch2, " ") : ''
}

export const generateAddress = (address?: TObject) => {
  let result: string = '-'
  if (address) {
    const firstLine = ((!address.address1 && !address.address2) ? '' : ((address.address1 ? address.address1 + ', ' : '') + (address.address2 || '') + '<br/>') ),
          secondLine = ((!address.subDistrict && !address.district && !address.city) ? '' : ((address.subDistrict ? address.subDistrict + ', ' : '') + (address.district ? address.district + ', ' : '') + (address.city || '') + '<br/>') ),
          thirdLine = ((address.postalCode ? (address.postalCode + '<br/>') : '')),
          lastLine = address.phone || ''
    result = firstLine + secondLine + thirdLine + lastLine
  }
  return result
}

export const evForceInput = (inputEl:HTMLInputElement, value: number) => {
  if (!inputEl) {
    return false
  }
  const inputValue = () => setTimeout(() => { inputEl.value = value.toString() }, 1)
  inputEl.addEventListener("focusin", inputValue , false)
  inputEl.focus()
  inputEl.removeEventListener("focusin", inputValue , false)
}

export const loadImage = (path: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.src = path
    img.onload = () => resolve(img)
    img.onerror = e => reject(e)
  })
}

export const generateValueTable = (input: string|number, key: string) => {
  let result = input
  if (key && input !== '') {
    const _key = key.toLowerCase()
    const _input = (typeof input === 'string') ? parseInt(input) : input
    if (/price/.test(_key)) {
      result = currencyNumber(_input)
    } else if (/width/.test(_key) || /length/.test(_key) || /height/.test(_key)) {
      result = input + ' cm'
    } else if (/weight/.test(_key)) {
      result = input + ' gr'
    } else if(/discount/.test(_key)) {
      result = input + ' %'
    }
  }
  return result
}

export const generateValue = (type: string, value: any) => {
  let result: any = ''
  switch (type) {
    case 'String':
      result = value ? value.toString().trim() : ''
      break
    case 'Integer':
      result = (value !== '' && value !== null) ? parseInt(value) : null
      break
    case 'Boolean':
      case 'Array':
      result = value
      break
    default:
      result = value.toString().trim()
  }
  return result
}

export const validateByApproveJs = (head: TObject, value: any, title?: string, valueMatch?: any) => {
  let result: TObject = { approved: true },
      _title: string = title || errorTitle(head.name)
  if (head.key.indexOf('options-option') !== -1) {
    if (value === '') {
      result = { approved: false, errors: [_title+' is required'] }
    }
  }
  if (head?.validations) {
    const typeData: string = head?.typeData || 'String'
    let rules: TObject = { title: _title }
    if (head.validations?.required) {
      switch (typeData) {
        case 'String':
          rules.required = true
        break
        case 'Integer': 
          const _value = (typeof value === 'undefined') ? '' : (isNaN(value) ? '' : value)
          const requiredIntegerRule = {
            expects: [
              'value'
            ],
            message: '{title} is required',
            validate: (value: any, pars: TObject) => {
              return pars.value !== '' && pars.value !== null
            }
          }
          approve.addTest(requiredIntegerRule, 'requiredIntegerRule')
          rules.requiredIntegerRule = {
            value: _value
          }
        break 
        case 'Array':
          const requiredArrayRule = {
            expects: [
              'value'
            ],
            message: '{title} is required',
            validate: (value: any, pars: TObject) => {
              return pars.value?.length || (pars.value && Object.keys(pars.value).length) ? true : false 
            }
          }
          approve.addTest(requiredArrayRule, 'requiredArrayRule')
          rules.requiredArrayRule = {
            value: value
          }
        break
        default: 
          rules.required = true
      }
    }
    if (head.validations?.min && head.validations?.max) {
      switch (typeData) {
        case 'Integer':
          const minMaxIntegerRule = {
            expects: [
              'value'
            ],
            message: '{title} min '+head.validations?.min+' and max '+head.validations?.max,
            validate: (value: any, pars: TObject) => {
              return (pars.value >= head.validations?.min && pars.value <= head.validations?.max) || pars.value === '' || typeof pars.value === 'undefined'
            }
          }
          approve.addTest(minMaxIntegerRule, 'minMaxIntegerRule')
          rules.minMaxIntegerRule = {
            value: value
          }
        break
        case 'Array':
          const minMaxArrayRule = {
            expects: [
              'value'
            ],
            message: '{title} must be a minimum of '+head.validations?.min+' and a maximum of '+head.validations?.max,
            validate: (value: any, pars: TObject) => {
              return pars.value ? (pars.value.length >= head.validations?.min &&  pars.value.length <= head.validations?.max) : false
            }
          }
          approve.addTest(minMaxArrayRule, 'minMaxArrayRule')
          rules.minMaxArrayRule = {
            value: value
          }
        break
        default: 
          rules.min = head.validations?.min
          rules.max = head.validations?.max
      }
    }
    if (head.validations?.min) {
      switch (typeData) {
        case 'Integer':
          const minIntegerRule = {
            expects: [
              'value'
            ],
            message: '{title} min '+head.validations?.min,
            validate: (value: any, pars: TObject) => {
              return pars.value >= head.validations?.min || pars.value === '' || typeof pars.value === 'undefined'
            }
          }
          approve.addTest(minIntegerRule, 'minIntegerRule')
          rules.minIntegerRule = {
            value: value
          }
        break
        case 'Array':
          const minArrayRule = {
            expects: [
              'value'
            ],
            message: '{title} must be a minimum of '+head.validations?.min,
            validate: (value: any, pars: TObject) => {
              return pars?.value?.length >= head.validations?.min
            }
          }
          approve.addTest(minArrayRule, 'minArrayRule')
          rules.minArrayRule = {
            value: value
          }
        break
        default: 
          rules.min = head.validations?.min
      }
    }
    if (head.validations?.max) {
      switch (typeData) {
        case 'Integer':
          const maxIntegerRule = {
            expects: [
              'value'
            ],
            message: '{title} max '+head.validations?.max,
            validate: (value: any, pars: TObject) => {
              return pars.value <= head.validations?.max || pars.value === '' || typeof pars.value === 'undefined'
            }
          }
          approve.addTest(maxIntegerRule, 'maxIntegerRule')
          rules.maxIntegerRule = {
            value: value
          }
        break
        case 'Array':
          const maxArrayRule = {
            expects: [
              'value'
            ],
            message: '{title} must be a maximum of '+head.validations?.max,
            validate: (value: any, pars: TObject) => {
              return pars?.value?.length <= head.validations?.max
            }
          }
          approve.addTest(maxArrayRule, 'maxArrayRule')
          rules.maxArrayRule = {
            value: value
          }
        break
        default: 
          rules.max = head.validations?.max
      }
    }
    if(head.validations?.lessEqual) {
      const lessEqualRule = {
        expects: [
          'value',
          'valueMatch',
        ],
        message: '{title} is less than or equal {valueMatch}',
        validate: (value: any, pars: TObject) => {
          return value <= (pars?.valueMatch || 0)
        }
      }
      approve.addTest(lessEqualRule, 'lessEqualRule')
      rules.lessEqualRule = {
        value: value,
        valueMatch: valueMatch
      }
    }
    result = approve.value(value, rules)
  }
  return result
} 

export const unescapeHTML = (value?: string) => {
  return value ? value.replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&amp;/g,'&') : value as string
}

export const getStorage = (name: string, type: string = 'string', defaultVal?: any, loadName: string = 'load') => {
  let result: any
  if (sessionStorage.getItem(loadName) !== null) {
    result = sessionStorage.getItem(name) !== null ? sessionStorage.getItem(name) : defaultVal
  } else {
    result = localStorage.getItem(name) !== null ? localStorage.getItem(name) : defaultVal
    localStorage.getItem(name) !== null && sessionStorage.setItem(name, result)
  }
  switch (type) {
    case 'boolean':
      result = (result !== undefined && result !== 'undefined') ? (result === 'true') : false
      break
    case 'number': 
    case 'integer':
      result = (result !== undefined && result !== 'undefined') ? parseInt(result) : 0
      break
    case 'object':
    case 'array':
      result = (result !== undefined && result !== 'undefined') ? JSON.parse(result) : undefined
      break  
    default:
      result = (result === undefined || result === 'undefined') ? '' : result
      break
  }
  return result
}

export const setStorage = (name: string, value?: any, only?: string) => {
  if (value === undefined || value === null) {
    return false
  }
  const _value: string = (typeof value !== 'string') ? ((typeof value === 'object') ? JSON.stringify(value) : value.toString()) : value
  switch (only) {
    case 'session':
      sessionStorage.setItem(name, _value)
      break
    case 'local':
      localStorage.setItem(name, _value)
      break
    default:
      sessionStorage.setItem(name, _value)
      localStorage.setItem(name, _value)
  }
}

export const unCamelCaseKeys = (object: TObject) => {  
  const unCamelCase = (value: string) => {
    return value.replace(/([a-z][A-Z])/g, function (g) { return g[0] + '_' + g[1].toLowerCase() })
  }
  return Object
    .entries(object)
    .reduce((carry: any, [key, value]) => {
      carry[unCamelCase(key)] = value
      return carry
    }, {})
}

export const generateOriginKey = (key: string, arrString: string[]) => {
  let result = key
  arrString.forEach(arr => result = result.replace(arr, ''))
  return result
}

export const generateDefaultValue = (field: TObject) => {
  let result: any = ''
  switch (field.fieldType) {
    case 'select':
    case 'select_async_pagination':
      result = null
      if (field.key === 'variants-tax' && !result) {
        const taxEl = document.querySelector('#tax') as HTMLDivElement
        if (taxEl && field.options?.length) {
          result = taxEl.innerHTML === 'true'
        }
      }
      break
    case 'image':
      result = (field.typeData  === 'Array') ? null : ''
      break
    case 'tag':
      result = (field.typeData  === 'Array') ? null : ''
      break
    case 'date':
      result = field.validations?.required ? moment(new Date()).format("DD/MM/YYYY") : ''
      break  
    case 'input':
      result = (field.typeData  === 'Integer') ? null : ''
      break    
    default:
      result = ''
  }
  return result
}

export const capitalize = (value?: string) => {
  return value ? (value[0].toUpperCase() + value.substring(1)) : ''
}

export const pasteIntoInput = (el:HTMLInputElement, text: string) => {
  el.focus()
  if (typeof el.selectionStart == 'number' && typeof el.selectionEnd == 'number') {
    var val = el.value
    var selStart = el.selectionStart
    el.value = val.slice(0, selStart) + text + val.slice(el.selectionEnd)
    el.selectionEnd = el.selectionStart = selStart + text.length
  } else if (typeof (document as any).selection != 'undefined') {
    var textRange = (document as any).selection.createRange()
    textRange.text = text
    textRange.collapse(false)
    textRange.select()
  }
}

export const slugify = (value?: string) => {
  return value ? value
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "") : ''
}

export const generateAutoCopyKey = (keyAlias: string, variantIdx: number, optionIdx: number, header: TObject) => {
  let result = keyAlias
  if (header?.condition === 'variant') {
    result += '-variant-'+variantIdx.toString()
  }
  if (keyAlias === 'options-type' || keyAlias === 'options-option') {
    result += '-'+optionIdx.toString()
  } 
  return result
}

export const isErrorEscape = (error?:ApolloError) => {
  return (error && error?.networkError?.name !== 'AbortError') ? true : false
}

export const maskPhoneNumber = (phone: string) => {
  const x = phone
    .replace(/\D/g, "")
    .match(/(\d?)(\d{0,20})/)
  return x ? x[0] : ''
} 

export const getSizeContainer = (full?: boolean) => {
  const height = window.innerHeight
  const width = window.innerWidth
  let result = { width: width, height: height }  
  if (width >= 1366) {
    result.width = width - 220 - 64
  } else if (width >= 1280) {
    result.width = width - 187 - 32
  }
  return result
}

export const getKeyListing = (accountId?: number, condition?: string) => {
  let keyStrings: string[] = ['listing_']
  accountId && keyStrings.push(`${accountId.toString()}_`)
  condition === 'variant' && keyStrings.push('variants-')
  return keyStrings
}

export const generateSUM = (names: string[] | string, data: any, type?: string) => {
  let result: number = 0
  if(data && names) {
    result = data.reduce((_sum: any, cur: any) => {
      let _name
      if (Array.isArray(names)) {
        _name = cur
        for (const name of names) {
          if (name in _name) {
            _name = _name[name]
          }
        }
      } else {
        _name = cur[names]
      }
      return _sum + (type === 'currency' ? parseInt(_name.replace(/,.*|[^0-9]/g, '')) : _name)
    }, 0)
  }
  return result
}

export const getURLExtension = (url: string) => {
  return url.split(/[#?]/)[0].split('.').pop()?.trim()
}

export const evCompareCacheListing = (existing: any, incoming: any, args: any, readField: any) => {
  let result = true
  // console.log(args, "args", existing, incoming)
  if (incoming?.edges && existing?.edges) {
    if (incoming.edges.length === existing.edges.length) {
      let BreakException = {}
      try {
        console.group("cache", args)
        existing.edges.forEach((listing: any, idx: number) => {
          const _listing = readField("node", listing)
          const _id = readField("id", _listing)
          const _variants = readField("variants", _listing)
          const listingInc = incoming.edges[idx]
          const _listingInc = readField("node", listingInc)
          const _idInc = readField("id", _listingInc)
          const _variantsInc = readField("variants", _listingInc)
          console.log(_id, "(existing)" , "=", _idInc, "(incoming)", "listing.id")
          if (_id !== _idInc) throw BreakException
          if(_variants?.length === _variantsInc?.length) {
            _variants.forEach((variant: any, variantIdx: number) => {
              const _id = readField("id", variant)
              const _idInc = readField("id", _variantsInc[variantIdx])
              console.log(_id, "(existing)" , "=", _idInc, "(incoming)", "listing.variant.id")
              if (_id !== _idInc) throw BreakException
            })
          }else throw BreakException
        })
        console.groupEnd()
      } catch (e) {
        if (e !== BreakException) {
          throw e
        } else {
          console.warn("different ->", existing, incoming, args)
          result = false
        }
      }
    } else {
      result = false
    }
  }

  return result
}

export const evUpdateInputRc = (input?: HTMLInputElement, value?: string | number) => {
  if (!input) return false
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
  )?.set
  nativeInputValueSetter && nativeInputValueSetter.call(input, (value === undefined ? '' : value))
  const inputEvent = new Event('input', { bubbles: true})
  input.dispatchEvent(inputEvent)
}

export const evGenerateValueMatch = (validation?: TObject, data?: any) => {
  let result = undefined
  if (validation && Object.keys(validation).length) {
    const keyArr = ['lessEqual']
    const key = keyArr.find((_key) => _key in validation)
    if (data && key) {
      const obj = validation[key]
      if(obj && obj in data) result = data[obj]
    }
  }
  return result
}

export const evScrollTo = (el?: HTMLElement, range?: number) => {
  const _range = range || 140
  if (el) {
    setTimeout(() => {
      const viewportEl = document.querySelector('._refMasterTableViewport') as HTMLElement
      const bodyRect = viewportEl.getBoundingClientRect()
      const elemRect = el.getBoundingClientRect()
      if (elemRect) {
        const offsetTop = (elemRect.top - bodyRect.top - _range) + viewportEl.scrollTop
        const offsetLeft = (elemRect.left - bodyRect.left) + viewportEl.scrollLeft
        viewportEl.scrollTo(offsetLeft,offsetTop)
      }      
    }, 10)
  }
}

export const evSortbySku = (results: any) => {
  return results.sort((a: any, b: any) => {
    const aValue: string = a?.product?.sku || ''
    const bValue: string = b?.product?.sku || ''
    if (aValue < bValue) {
      return 1
    }
    if (aValue > bValue) {
      return -1
    }
    return 0
  })
}